import { Button, Drawer, Flex, Space } from "antd";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getAllPinChat, getListPinChat } from "../../../../api/chatApi";
import { useLocation } from "react-router-dom";

const PinList = ({ pinListDraw }: any) => {
  const idChat = localStorage.getItem("current-chat-id");
  const location = useLocation();
  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleGetListPin = async () => {
    if (idChat) {
      const res: any = await getAllPinChat();
      if (res.code === 200) {
        console.log(res.data);
      }
    }
  };

  useEffect(() => {
    handleGetListPin();
  }, [idChat, pinListDraw.visible]);

  useEffect(() => {
    if (pinListDraw.visible) {
      pinListDraw.onCancel();
    }
  }, [location.pathname]);

  return (
    <Drawer
      placement="right"
      size="default"
      onClose={pinListDraw.onCancel}
      open={pinListDraw.visible}
      mask={false}
    >
      <Flex
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          padding: 10,
          borderRadius: 8,
        }}
        className="cursor-pointer"
        onClick={() =>
          copyToClipboard(
            "BOO khởi đầu là một cửa hàng bán quần áo và phụ kiện trượt ván trên phố Nam Cao (gần trường Amsterdam cũ) mang phong cách hiphop với văn hóa và thời trang đường phố."
          )
        }
      >
        <p>
          BOO khởi đầu là một cửa hàng bán quần áo và phụ kiện trượt ván trên
          phố Nam Cao (gần trường Amsterdam cũ) mang phong cách hiphop với văn
          hóa và thời trang đường phố.
        </p>
      </Flex>
    </Drawer>
  );
};

export default PinList;
