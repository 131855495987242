import { Flex, Form, Modal, Select, Space, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getListTeam } from "../../../../api/teamApi";
import { MODAL_TYPE } from "../../../../constants/modalType";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import "./Component.scss";

const AddTeamToDocumentModal = ({ addTeamModal, handleShareTeam }: any) => {
  const [form] = Form.useForm();
  const [listTeam, setListTeam] = useState<any>([]);
  const [listTeamAccessId, setListTeamAccessId] = useState([]);
  const [listTeamAdded, setListTeamAdded] = useState([]);

  const handleGetListTeamAdd = async () => {
    const data: any = await getListTeam();
    if (data.code === 200 && data.data !== null) {
      const dataFilter = data.data?.filter(
        (team: any) => team.name !== "Public"
      );

      const listTeamAccess: any = addTeamModal.data?.sharedTeam.filter(
        (team: any) => team.sharedTeam.name !== "Public"
      );
      setListTeamAdded(listTeamAccess);

      const listTeamValues = listTeamAccess?.map((team: any) => {
        return team?.sharedTeam?.id;
      });
      // setListTeamAccessId(listTeamValues);
      const dataFormat = dataFilter.map(async (items: any) => {
        return {
          value: items.id,
          label: items.name,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          disabled: listTeamValues.includes(items.id),
        };
      });
      const listData: any = await Promise.all(dataFormat);
      setListTeam(listData.reverse());
    } else {
      setListTeam([]);
    }
  };

  const handleGetListTeamRemove = async () => {
    const listTeamAccess: any = addTeamModal.data?.sharedTeam.filter(
      (team: any) => team.sharedTeam.name !== "Public"
    );
    setListTeamAdded(listTeamAccess);
    const listTeamValues = listTeamAccess?.map((Team: any) => {
      return Team.sharedTeam.id;
    });
    form.setFieldValue("targetIDs", listTeamValues);
    setListTeamAccessId(listTeamValues);

    const listTeamOptions = listTeamAccess?.map((info: any) => {
      const { sharedTeam } = info;
      return {
        value: sharedTeam.id,
        label: sharedTeam.name,
      };
    });
    setListTeam(listTeamOptions);
  };

  const onFinish = async (values: any) => {
    let targetIDs = values.targetIDs || [];
    if (addTeamModal.data?.type === MODAL_TYPE.REMOVE) {
      targetIDs = listTeamAccessId.filter((Team: any) => {
        return !targetIDs.includes(Team);
      });
      if (targetIDs.length === 0) {
        toast.warning("You must remove at least one Team in the group");
        return;
      }
    }
    const params = {
      target: "team",
      targetIDs: targetIDs,
      shareAll: false,
      permission:
        addTeamModal.data?.type === MODAL_TYPE.ADD ? "pg__reader" : "none",
    };
    const res: any = await handleShareTeam(params, addTeamModal.data?.id);
    if (res === true) {
      addTeamModal.onCancel();
      toast.success(
        `${
          addTeamModal.data?.type === MODAL_TYPE.ADD
            ? "Add Team"
            : "Remove Team"
        } success!`
      );
      form.resetFields();
    } else {
      toast.warning(`${capitalizeFirstLetter(res)}`);
    }
  };

  useEffect(() => {
    if (addTeamModal.visible) {
      if (addTeamModal.data?.type === MODAL_TYPE.ADD) {
        handleGetListTeamAdd();
      } else {
        handleGetListTeamRemove();
      }
    }
    return () => {
      form.resetFields();
      setListTeam([]);
      setListTeamAdded([]);
    };
  }, [addTeamModal.visible, addTeamModal.data?.type]);

  return (
    <Modal
      open={addTeamModal.visible}
      onCancel={() => {
        addTeamModal.onCancel();
        form.resetFields();
      }}
      className="create-document-modal"
      onOk={form.submit}
      title={
        addTeamModal.data?.type === MODAL_TYPE.ADD ? "Add Team" : "Remove Team"
      }
    >
      <Form form={form} onFinish={onFinish} className="document-form">
        <div className="create-document-wrap">
          <Form.Item name="targetIDs">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Team"
              optionLabelProp="label"
              options={listTeam}
              tagRender={() => <></>}
              optionRender={(option) => <Space>{option.data.label}</Space>}
            />
          </Form.Item>
        </div>
      </Form>
      {listTeamAdded?.length > 0 && (
        <Flex vertical gap={5} style={{ padding: 10 }}>
          <Typography.Text strong>Teams in document:</Typography.Text>
          <div style={{ overflow: "scroll", maxHeight: 300 }}>
            {listTeamAdded?.map((team: any) => (
              <div key={team.id}>
                <Flex align="center" gap={5}>
                  <Flex vertical>
                    {team.fullName}
                    <span style={{ color: "#000" }}>
                      {team.sharedTeam.name}
                    </span>
                  </Flex>
                </Flex>
              </div>
            ))}
          </div>
        </Flex>
      )}
    </Modal>
  );
};

export default AddTeamToDocumentModal;
