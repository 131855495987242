/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import { Button, Grid, InputBase, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { Checkbox, Popconfirm } from "antd";
import _ from "lodash";
import moment from "moment";
import { darken } from "polished";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  blockShareAllDocument,
  createDocument,
  deleteDocument,
  generalAccess,
  getListDocumentActive,
  shareAllDocument,
  shareDocumentToUser,
  updateDocument,
} from "../../../api/documentApi";
import {
  downloadMedia,
  getMedia,
  getMediaSummary,
} from "../../../api/mediaApi";
import { MEDIA_SUB_TYPE_NAME, MEDIA_TYPE } from "../../../constants/mediaType";
import { MODAL_TYPE } from "../../../constants/modalType";
import { ROLE } from "../../../constants/role";
import { useModalProps } from "../../../hooks/useModalProps";
import { usePermissionValidate } from "../../../hooks/usePermissionValidate";
import useViewport from "../../../hooks/useViewport";
import AddTeamToDocumentModal from "./components/AddTeamToDocumentModal";
import AddUserToDocumentModal from "./components/AddUserToDocumentModal";
import CreateAndEditDocumentModal from "./components/CreateAndEditDocumentModal";
import DocumentItem from "./components/DocumentItem";
import MediaInDocumentModal from "./components/MediaInDocumentModal";

const Search = styled.div`
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  background: linear-gradient(to right, rgb(118, 213, 251), rgb(246, 94, 225));
  display: none;
  position: relative;
  width: 100%;
  height: 50px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  > input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    caret-color: rgb(255, 81, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding-left: 15px;
    letter-spacing: 0.8px;
    color: rgb(19, 19, 19);
    font-size: 15px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const DocumentsPage = () => {
  const { isWrite } = usePermissionValidate("f__document");
  const { t }: any = useTranslation();
  const viewPort = useViewport();

  const isMobile = viewPort.width <= 958;

  const createAndEditDocumentModal = useModalProps();
  const addUserModal = useModalProps();
  const addTeamModal = useModalProps();
  const mediaInDocumnetModal = useModalProps();

  const [listDocument, setlistDocument] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [cursor, setCursor] = useState("");
  const [query, setQuery] = useState("");

  const listRef = useRef<any>(null);

  const handleGetListDocuments = async (cursorId = "", keyword = "") => {
    setLoading(true);
    const data: any = await getListDocumentActive(cursorId, keyword);
    if (data.code === 200 && data.data !== null) {
      const activeData = data?.data;
      const dataFormat = activeData?.map(async (items: any) => {
        const { fileIds } = items;
        const mediaList =
          fileIds?.length > 0
            ? fileIds.map(async (media: any) => {
                const info = await getMedia(media);
                if (info) {
                  const summaryMedia = await getMediaSummary(media);

                  return {
                    ...info.data,
                    label: info?.data?.name,
                    summaryMedia: summaryMedia.data
                      ? summaryMedia.data.summarizedContent
                      : "",
                  };
                } else {
                  return {};
                }
              })
            : [];
        const mediaData = await Promise.all(mediaList);

        const documentShareStatus = await generalAccess(items.id);
        const isShareAll = documentShareStatus.data
          ? documentShareStatus.data?.findIndex(
              (val: any) => val?.sharedTeam?.name === "Public"
            )
          : -1;

        return {
          id: items.id,
          title: items.title,
          description: items.description,
          owner: items.owner.fullName,
          updated_at: moment(items.createdAt).format("MMM DD YYYY"),
          size: "500KB",
          file: "pdf",
          fileIds: items.fileIds,
          key: items.id,
          mediaData: mediaData,
          isShareAll: isShareAll !== -1 ? true : false,
          sharedTeam: documentShareStatus.data ? documentShareStatus.data : [],
        };
      });
      const listData: any = await Promise.all(dataFormat);

      setHasMore(data?.meta?.has_more);
      if (data?.meta?.has_more) {
        setCursor(data?.meta?.cursor);
      } else {
        setCursor("");
      }
      setLoading(false);
      return listData;
    } else {
      setHasMore(false);
      setCursor("");
      setLoading(false);
      return [];
    }
  };

  const handleDownload = async (medias: any) => {
    if (medias.mediaData && medias.mediaData.length) {
      medias.mediaData.forEach(async (media: any) => {
        if (media.type === MEDIA_TYPE.VIDEO) {
          return;
        } else {
          const file: any = await downloadMedia(media.id);
          if (file) {
            const blob = new Blob([file], { type: "application/pdf" });
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement("a");
            link.href = url;
            link.setAttribute("download", media.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        }
      });
    }
  };

  const handleShareFile = async (id: string, isShareAll: boolean) => {
    try {
      let res: any = null;
      if (isShareAll) {
        res = await blockShareAllDocument(id);
      } else {
        res = await shareAllDocument(id);
      }
      if (res.code === 200) {
        if (isShareAll) {
          toast.success("Block document success!");
        } else {
          toast.success("Share document success!");
        }
        const listData = await handleGetListDocuments();
        setlistDocument(listData);
      } else {
        toast.warning("Server have problem, please try again later");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShareUser = async (params: any, shareId: string) => {
    if (shareId === null) return false;
    const res: any = await shareDocumentToUser(shareId, params);
    if (res.code === 200) {
      return true;
    } else {
      return res.message;
    }
  };

  const handleShareTeam = async (params: any, shareId: string) => {
    if (shareId === null) return false;
    const res: any = await shareDocumentToUser(shareId, params);
    if (res.code === 200) {
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
      return true;
    } else {
      return res.message;
    }
  };

  const handleDeleteDocument = async (idList: any) => {
    if (idList.length === 0) return;
    const res: any = await deleteDocument(idList);
    if (res.code === 200) {
      toast.success("Delete document success!");
      setSelectedRowKeys([]);
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
    } else {
      toast.warning("Server have problem, please try again later");
    }
  };

  const handleCheckMobile = (checked: any, id: any) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, id]);
    } else {
      const newList = selectedRowKeys.filter((val) => val !== id);
      setSelectedRowKeys(newList);
    }
  };

  const handleCheckAllMobile = () => {
    if (listDocument.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      const listKey = listDocument.map((items: any) => items.id);
      setSelectedRowKeys(listKey);
    }
  };

  const handlePreview = async (item: any) => {
    if (item.type === MEDIA_TYPE.VIDEO) {
      const url = item.storageData.link;
      window.open(url);
    } else {
      const file: any = await downloadMedia(item.id);
      if (file) {
        const blob = new Blob([file], {
          type: MEDIA_SUB_TYPE_NAME[item.subType],
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  };

  const handleCreateUpdateDocument = async (
    values: any,
    id: string,
    type: string
  ) => {
    let fileIds = values.fileIds?.length ? values.fileIds : [];
    if (type === MODAL_TYPE.EDIT && fileIds[0]?.label) {
      fileIds = fileIds.map((media: any) => media.value);
    }
    const params = {
      title: values.title,
      description: values.description,
      fileIds: fileIds,
    };
    let res: any;
    if (!id) {
      res = await createDocument(params);
    } else {
      res = await updateDocument(params, id);
    }
    if (res.code === 200) {
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
      toast.success(`${!id ? "Create" : "Update"} document  success!`);
      return true;
    } else {
      toast.error(`${!id ? "Create" : "Update"} document failed!`);
      return false;
    }
  };

  const handleFetchMore = async () => {
    if (hasMore) {
      const listData = await handleGetListDocuments(cursor);
      setlistDocument((prev: any) => [...prev, ...listData]);
    }
  };

  const handleSearch = async (value: any) => {
    setQuery(value);
    if (value !== "") {
      const listData = await handleGetListDocuments("", value);
      setlistDocument(listData);
    } else {
      const listData = await handleGetListDocuments();
      setlistDocument(listData);
    }
  };
  const handleScrollMobile = async (e: any) => {
    if (
      listRef.current.clientHeight + listRef.current.scrollTop ===
        listRef.current.scrollHeight &&
      hasMore
    ) {
      await handleFetchMore();
    }
  };

  const debouncedSearch = _.debounce(handleSearch, 500);

  useEffect(() => {
    debouncedSearch(query);
    return () => {
      debouncedSearch.cancel();
    };
  }, [query]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.style.height = `${window.innerHeight - 200}px`;
    }
  }, [listRef]);

  useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.addEventListener("scroll", handleScrollMobile);
    }
    return () => {
      if (listRef.current !== null) {
        listRef.current.removeEventListener("scroll", handleScrollMobile);
      }
    };
  }, [listRef.current, hasMore]);

  return (
    <div>
      <Grid
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <Grid item style={{ width: "55%" }}>
            <Search>
              <Input
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t("Search")}
                value={query}
              />
            </Search>
          </Grid>
        )}
        {isWrite && (
          <Grid
            item
            style={{
              width: isMobile ? "100%" : "40%",
              display: "flex",
              justifyContent: "flex-start",
              gap: 10,
              alignItems: "center",
            }}
          >
            <Button
              component="label"
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              style={{ borderRadius: 16, height: 45 }}
              onClick={() =>
                createAndEditDocumentModal.onOpen({
                  type: MODAL_TYPE.CREATE,
                })
              }
            >
              New Document
            </Button>
            <Popconfirm
              placement="topLeft"
              title="Delete Document"
              description="Are you sure you want to delete all selected documents?"
              onConfirm={() => handleDeleteDocument(selectedRowKeys)}
            >
              <Button
                variant="contained"
                style={{ borderRadius: 16, height: 45 }}
                startIcon={<DeleteOutlineOutlinedIcon />}
              >
                Trash
              </Button>
            </Popconfirm>
          </Grid>
        )}
      </Grid>
      <div>
        <>
          {isWrite && (
            <>
              <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <div>
                  <Checkbox
                    onChange={(e) => {
                      handleCheckAllMobile();
                    }}
                    checked={
                      selectedRowKeys.length === listDocument.length &&
                      listDocument.length > 0
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <hr />
            </>
          )}
          <div ref={listRef} style={{ overflow: "auto" }}>
            {listDocument.map((item: any) => (
              <DocumentItem
                key={item.id}
                item={item}
                createAndEditDocumentModal={createAndEditDocumentModal}
                addUserModal={addUserModal}
                addTeamModal={addTeamModal}
                handleDownload={handleDownload}
                handleShareFile={handleShareFile}
                handleDeleteDocument={handleDeleteDocument}
                loading={loading}
                mediaInDocumnetModal={mediaInDocumnetModal}
                handleCheckMobile={handleCheckMobile}
                selectedRowKeys={selectedRowKeys}
                isWrite={isWrite}
                isMobile={isMobile}
              />
            ))}
          </div>
        </>
      </div>

      <CreateAndEditDocumentModal
        handleGetListDocuments={handleGetListDocuments}
        createAndEditDocumentModal={createAndEditDocumentModal}
        handleCreateUpdateDocument={handleCreateUpdateDocument}
      />
      <AddUserToDocumentModal
        addUserModal={addUserModal}
        handleShareUser={handleShareUser}
      />
      <AddTeamToDocumentModal
        addTeamModal={addTeamModal}
        handleShareTeam={handleShareTeam}
      />
      <MediaInDocumentModal
        mediaInDocumnetModal={mediaInDocumnetModal}
        handlePreview={handlePreview}
      />
    </div>
  );
};

const DocumentItemMobile = ({
  items,
  createAndEditDocumentModal,
  handleDownload,
  handleShareFile,
  addUserModal,
  handleDeleteDocument,
  handleCheckMobile,
  selectedRowKeys,
}: any) => {
  const { user } = useSelector((state: any) => state.user);
  const [isShowAction, setIsShowAction] = useState(false);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 7 }}>
          <Checkbox
            checked={selectedRowKeys.includes(items.id) ? true : false}
            onChange={(e) => {
              handleCheckMobile(e.target.checked, items.id);
            }}
          />
          <FolderCopyOutlinedIcon /> {items.title} ({items.owner})
        </div>
        <div>
          <MoreHorizOutlinedIcon
            onClick={() => setIsShowAction(!isShowAction)}
          />
        </div>
      </div>
      {isShowAction && (
        <div
          style={{
            display: "flex",
            gap: 10,
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) && (
            <EditOutlinedIcon
              fontSize="medium"
              onClick={() => {
                createAndEditDocumentModal.onOpen({
                  title: items.title,
                  description: items.description,
                  id: items.id,
                  mediaData: items?.mediaData,
                  type: MODAL_TYPE.EDIT,
                });
              }}
              className="cursor-pointer"
            />
          )}
          <DownloadOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={() => handleDownload(items)}
          />
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) && (
            <Popconfirm
              placement="topLeft"
              title="Share"
              description={`Are you sure to ${
                items.isShareAll ? "block" : "share"
              } this file with everyone?`}
              onConfirm={() => handleShareFile(items.id, items.isShareAll)}
            >
              {!items.isShareAll ? (
                <VpnLockOutlinedIcon
                  fontSize="medium"
                  className="cursor-pointer"
                />
              ) : (
                <PublicOutlinedIcon
                  fontSize="medium"
                  className="cursor-pointer"
                />
              )}
            </Popconfirm>
          )}
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) && (
            <PersonAddOutlinedIcon
              fontSize="medium"
              className="cursor-pointer"
              onClick={() => {
                addUserModal.onOpen({ id: items.id, type: MODAL_TYPE.ADD });
              }}
            />
          )}
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) &&
            !items.isShareAll && (
              <PersonRemoveOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
                onClick={() => {
                  addUserModal.onOpen({
                    id: items.id,
                    type: MODAL_TYPE.REMOVE,
                  });
                }}
              />
            )}
          {(items.owner === user?.fullName || user?.role === ROLE.OWNER) && (
            <Popconfirm
              placement="topLeft"
              title="Delete Document"
              description="Are you sure you want to delete this document?"
              onConfirm={() => handleDeleteDocument([items?.id])}
            >
              <DeleteOutlineOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
              />
            </Popconfirm>
          )}
        </div>
      )}
      <Divider />
    </>
  );
};

export default DocumentsPage;
