import { Button, Collapse, Flex, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import {
  quickPrompts,
  quickPromptsWithId,
} from "../../../../api/suggestionApi";
import LensBlurOutlinedIcon from "@mui/icons-material/LensBlurOutlined";
import { getMedia } from "../../../../api/mediaApi";

const Suggestions = ({
  mesageListLength,
  lastMessage,
  setMessage,
  isDisableSendMessage,
}: any) => {
  const [accordion, setAccordion] = useState(false);
  const [listPrompt, setListPrompt] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>([]);

  const suggestionRef = useRef<any>(null);

  const handleGetQuickPromts = async () => {
    const res: any = await quickPrompts();
    if (res.code === 200) {
      setListPrompt(res.data);
    }
  };

  const handleGetQuickPromtsDocument = async () => {
    if (lastMessage?.documentId.length > 0 && !!lastMessage.documentId[0]) {
      const res: any = await getMedia(lastMessage.documentId[0]);
      if (res?.code === 200) {
        const documentId = res?.data?.documentId;
        const resQuickPrompt: any = await quickPromptsWithId(documentId);
        if (resQuickPrompt.code === 200) {
          setListPrompt(resQuickPrompt.data);
        }
      }
    }
  };

  const suggestionsContent = () => {
    return (
      <Flex
        style={{
          //   height: 120,
          background: "#fafaf9",
          width: "100%",
          borderRadius: 5,
          padding: 10,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
        vertical
      >
        {listPrompt.map((p: any, idx: number) => (
          <Flex key={idx} style={{ marginBottom: 10, cursor: "pointer" }}>
            <LensBlurOutlinedIcon />
            <Typography
              onClick={() => {
                setMessage(p.prompt);
                setAccordion(false);
                setActiveKey([]);
              }}
            >
              {p.prompt}
            </Typography>
          </Flex>
        ))}
      </Flex>
    );
  };

  // useEffect(() => {
  //   if (suggestionRef.current) {
  //     if (accordion) {
  //       suggestionRef.current.style.marginTop = "-180px";
  //     } else {
  //       suggestionRef.current.style.marginTop = "-40px";
  //     }
  //   }
  // }, [accordion]);

  useEffect(() => {
    handleGetQuickPromtsDocument();
  }, [lastMessage]);

  useEffect(() => {
    if (mesageListLength === 0) {
      handleGetQuickPromts();
    }
  }, []);

  if (isDisableSendMessage) return <></>;

  return mesageListLength === 0 ? (
    <div
      style={{
        position: "absolute",
        left: "5%",
        zIndex: 999,
        top: "20%",
        transition: "all 0.5s ease-in-out",
        width: "90%",
      }}
    >
      {suggestionsContent()}
    </div>
  ) : (
    <div
      style={{
        top: "45px",
        position: "absolute",
        left: "5%",
        zIndex: 999,
        marginTop: "-50px",
        transition: "all 0.5s ease-in-out",
        width: "90%",
      }}
      ref={suggestionRef}
    >
      <Collapse
        activeKey={activeKey}
        accordion={accordion}
        onChange={(activeList) => {
          if (activeList.length > 0) {
            setActiveKey(activeList);
            setAccordion(true);
          } else {
            setActiveKey([]);
            setAccordion(false);
          }
        }}
        size="small"
        ghost
        items={[
          {
            key: "1",
            label: (
              <Flex justify="center">
                <Button type="primary">
                  <Flex style={{ width: "100%" }}>
                    {accordion ? (
                      <KeyboardDoubleArrowDownOutlinedIcon />
                    ) : (
                      <KeyboardDoubleArrowUpOutlinedIcon />
                    )}
                    Suggestions
                  </Flex>
                </Button>
              </Flex>
            ),
            children: suggestionsContent(),
            showArrow: false,
          },
        ]}
      />
      {/* <Button type="primary">
        <Flex>
          <KeyboardDoubleArrowUpOutlinedIcon />
          Suggestions
        </Flex>
      </Button> */}
    </div>
  );
};

export default React.memo(Suggestions);
