import styled from "@emotion/styled";
import { Avatar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { downloadMedia, getMedia } from "../../../../api/mediaApi";
import { MEDIA_SUB_TYPE, MEDIA_TYPE } from "../../../../constants/mediaType";
import { useSelector } from "react-redux";
import botAvatar from "../../../../assets/image/botAvatar.png";
import { Flex, Popover } from "antd";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { PushpinOutlined } from "@ant-design/icons";
import { pinMessage } from "../../../../api/chatApi";
import { toast } from "react-toastify";

const ChatMessage = styled.div<{ position: "left" | "right" }>`
  margin: 5px;
  text-align: ${(props) => props.position};
  margin-left: ${(props) => (props.position === "right" ? "15px" : "15px")};
  margin-right: ${(props) => (props.position === "left" ? "15px" : "15px")};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: flex;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted ? props.theme.palette.secondary.main : "transparent"};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
  position: relative;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

interface ChatMessageComponentType {
  message: string;
  position: "left" | "right";
  documentId?: [];
  chatBoxRef?: any;
  chatItem?: any;
}

const ChatMessageComponent = ({
  message,
  position = "left",
  documentId,
  chatItem,
}: ChatMessageComponentType) => {
  const [media, setMedia] = useState<any>([]);

  const handleGetPost = async () => {
    if (!documentId || documentId.length === 0) {
      return;
    }

    const listDocument: any = documentId.map(async (id: any) => {
      if (id.length <= 0) return;
      const res: any = await getMedia(id);
      return res;
    });
    const list: any = await Promise.all(listDocument);
    setMedia(list);
  };

  const handlePreview = async (data: any) => {
    if (data) {
      if (
        data.type === MEDIA_TYPE.VIDEO ||
        data.subType === MEDIA_SUB_TYPE.YOUTUBE
      ) {
        const url = data.storageData?.link;
        window.open(url);
      } else {
        const file: any = await downloadMedia(data.id);
        if (file) {
          const blob = new Blob([file], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      }
    }
  };

  const renderTextWithBold = (text: string): JSX.Element => {
    // Sử dụng regex để tìm và thay thế phần văn bản trong dấu "**"
    const parts = text.split(/(\*\*[^*]+\*\*)/g).map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        // Nếu phần này nằm trong dấu "**", bôi đen nó
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else {
        // Nếu không, giữ nguyên phần văn bản này
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });

    return <span>{parts}</span>;
  };

  const handlePin = async () => {
    if (chatItem.id) {
      const idChat = localStorage.getItem("current-chat-id");
      const res: any = await pinMessage(idChat, chatItem.id);
      if (res.code === 200) {
        toast.success("Pin message success!");
      }
    }
  };

  useEffect(() => {
    handleGetPost();
  }, [documentId]);

  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <Flex gap={5}>
          {position === "left" && <ChatMessageAvatar alt="" src={botAvatar} />}
          <ChatMessageBubble highlighted={position === "right"}>
            <Typography style={{ whiteSpace: "pre-line" }}>
              {renderTextWithBold(message)}
            </Typography>
            {position === "left" && (
              <Flex align="start">
                <PushpinOutlined
                  className="cursor-pointer"
                  onClick={handlePin}
                  style={{ fontSize: 20 }}
                />
              </Flex>
            )}
          </ChatMessageBubble>
          {position === "right" && <Avatar alt="" />}
        </Flex>
      </ChatMessageInner>
      {position === "left" && documentId?.length && media.length > 0 && (
        <div>
          <div
            style={{
              backgroundColor: "rgba(249,250,254)",
              padding: 10,
              borderRadius: 8,
            }}
          >
            <span
              style={{
                fontSize: 14,
                marginBottom: 10,
                display: "inline-block",
              }}
            >
              Sources
            </span>
            {media.map((m: any, idx: number) => (
              <Typography
                variant="h6"
                className="cursor-pointer"
                onClick={() => handlePreview(m?.data)}
                key={m.id ? m.id + idx : idx}
              >
                {idx + 1}.{m?.data?.name}
              </Typography>
            ))}
          </div>
        </div>
      )}
    </ChatMessage>
  );
};

export default React.memo(ChatMessageComponent);
