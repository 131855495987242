import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import GroupRemoveOutlinedIcon from "@mui/icons-material/GroupRemoveOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import VpnLockOutlinedIcon from "@mui/icons-material/VpnLockOutlined";
import {
  Avatar,
  Card,
  Checkbox,
  Divider,
  Flex,
  Popconfirm,
  Popover,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { MODAL_TYPE } from "../../../../constants/modalType";
import "./Component.scss";
import React from "react";

const { Meta } = Card;
const { Paragraph, Text } = Typography;

const DocumentItem = ({
  item,
  createAndEditDocumentModal,
  addUserModal,
  addTeamModal,
  handleDownload,
  handleShareFile,
  handleDeleteDocument,
  loading = false,
  mediaInDocumnetModal,
  handleCheckMobile,
  selectedRowKeys,
  isWrite,
  isMobile,
}: any) => {
  const renderTextWithBold = (text: string): JSX.Element => {
    // Sử dụng regex để tìm và thay thế phần văn bản trong dấu "**"
    const parts = text.split(/(\*\*[^*]+\*\*)/g).map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        // Nếu phần này nằm trong dấu "**", bôi đen nó
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else {
        // Nếu không, giữ nguyên phần văn bản này
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });

    return <span>{parts}</span>;
  };

  const renderAction = () => {
    return (
      <div style={{ display: "flex", gap: 10, cursor: "pointer" }}>
        <EditOutlinedIcon
          fontSize="medium"
          onClick={() => {
            createAndEditDocumentModal.onOpen({
              title: item.title,
              description: item.description,
              id: item.id,
              mediaData: item.mediaData,
              type: MODAL_TYPE.EDIT,
            });
          }}
          className="cursor-pointer"
        />
        <DownloadOutlinedIcon
          fontSize="medium"
          className="cursor-pointer"
          onClick={() => handleDownload(item)}
        />
        <Popconfirm
          title="Share"
          placement="topLeft"
          description={`Are you sure to ${
            item.isShareAll ? "block" : "share"
          } this file with everyone?`}
          onConfirm={() => handleShareFile(item.id, item.isShareAll)}
        >
          {!item.isShareAll ? (
            <VpnLockOutlinedIcon fontSize="medium" className="cursor-pointer" />
          ) : (
            <PublicOutlinedIcon fontSize="medium" className="cursor-pointer" />
          )}
        </Popconfirm>
        <PersonAddAltOutlinedIcon
          fontSize="medium"
          className="cursor-pointer"
          onClick={() => {
            addUserModal.onOpen({
              id: item.id,
              type: MODAL_TYPE.ADD,
            });
          }}
        />
        <PersonRemoveOutlinedIcon
          fontSize="medium"
          className="cursor-pointer"
          onClick={() => {
            addUserModal.onOpen({
              id: item.id,
              type: MODAL_TYPE.REMOVE,
            });
          }}
        />
        <GroupAddOutlinedIcon
          fontSize="medium"
          className="cursor-pointer"
          onClick={() => {
            addTeamModal.onOpen({
              id: item.id,
              type: MODAL_TYPE.ADD,
              sharedTeam: item.sharedTeam,
            });
          }}
        />
        <GroupRemoveOutlinedIcon
          fontSize="medium"
          className="cursor-pointer"
          onClick={() => {
            addTeamModal.onOpen({
              id: item.id,
              type: MODAL_TYPE.REMOVE,
              sharedTeam: item.sharedTeam,
            });
          }}
        />
        <Popconfirm
          placement="topLeft"
          title="Delete document"
          description="Are you sure you want to delete this document?"
          onConfirm={() => handleDeleteDocument([item?.id])}
        >
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
          />
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <Card bordered={false} loading={loading} className="card-document-item">
        <Flex gap={10}>
          <Flex align="flex-start">
            {isWrite && (
              <Checkbox
                style={{ marginTop: 12 }}
                checked={selectedRowKeys.includes(item.id) ? true : false}
                onChange={(e) => {
                  handleCheckMobile(e.target.checked, item.id);
                }}
              />
            )}
          </Flex>
          <div style={{ width: "100%" }}>
            <Flex
              className="card-document-item__header"
              justify="space-between"
              align="center"
            >
              <div>
                <h4>
                  {item.title} (<span>Owner: {item.owner}</span>)
                </h4>
                <p className="item-updated-time">{item.updated_at}</p>
              </div>
              {isMobile ? (
                <>
                  {isWrite && (
                    <div className="item-opration">
                      <Popover
                        content={renderAction}
                        placement="left"
                        trigger="click"
                      >
                        <MoreHorizOutlinedIcon fontSize="medium" />
                      </Popover>
                    </div>
                  )}
                </>
              ) : (
                <>{isWrite && renderAction()}</>
              )}
            </Flex>
            <div className="card-document-item__content">
              {item?.mediaData[0]?.summaryMedia ? (
                <div>
                  <Flex align="flex-start" gap={5}>
                    {/* <span style={{ fontSize: 5, marginTop: 8 }}>⚫</span>{" "} */}
                    <Paragraph
                      ellipsis={{ rows: 4 }}
                      style={{ whiteSpace: "pre-line", maxWidth: 900 }}
                    >
                      {item.mediaData
                        ? renderTextWithBold(item.mediaData[0].summaryMedia)
                        : ""}
                    </Paragraph>
                  </Flex>
                  <div>
                    <p
                      className="show-more-button"
                      onClick={() =>
                        mediaInDocumnetModal.onOpen(item.mediaData)
                      }
                    >
                      Show more
                    </p>
                  </div>
                </div>
              ) : (
                <Flex>
                  <CachedOutlinedIcon />
                  <Typography>Loading Summary</Typography>
                </Flex>
              )}
            </div>
          </div>
        </Flex>
        <Divider />
      </Card>
    </>
  );
};

export default DocumentItem;
