import axiosClient from "./axiosClient";

export const downloadMedia = (id) => {
  const url = `/p/media/${id}/download`;
  return axiosClient.get(url, { responseType: "blob" });
};

export const getMedia = (id) => {
  const url = `/p/media/${id}`;
  return axiosClient.get(url);
};

export const updateMedia = (params, id) => {
  const url = `p/media/${id}`;
  return axiosClient.put(url, params);
};

export const uploadNewMediaFile = (params) => {
  const url = "/p/media";
  return axiosClient.post(url, params);
};

export const uploadNewMediaFileWithProgress = (params, callBack, idx) => {
  const url = "/p/media";
  return axiosClient.post(url, params, {
    onUploadProgress: async (progressEvent) => {
      await callBack(progressEvent, idx);
    },
  });
};

export const uploadNewMediaLink = (params) => {
  const url = `/p/media/create`;
  return axiosClient.post(url, params);
};

export const deleteMedia = (idList) => {
  const url = `p/media`;
  const params = {
    mediaIds: idList,
    status: "trash",
  };
  return axiosClient.patch(url, params);
};

export const revertMedia = (idList) => {
  const url = `p/media`;
  const params = {
    mediaIds: idList,
    status: "active",
  };
  return axiosClient.patch(url, params);
};

export const getListMedia = (cursor = "", keyword = "") => {
  const url = `/p/media?status=trash&cursor=${cursor}&keyword=${keyword}`;
  return axiosClient.get(url);
};

export const getListMediaAvailable = (cursor = "", keyword = "") => {
  const available = true;
  const url = `/p/media?available=${available}&cursor=${cursor}&keyword=${keyword}`;
  return axiosClient.get(url);
};

export const getMediaSummary = (id) => {
  const url = `/p/media/${id}/summary`;
  return axiosClient.get(url);
};
