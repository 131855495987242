import axiosClient from "./axiosClient";

export const quickPrompts = () => {
  const url = `/p/suggestion/mine/quickPrompts`;
  return axiosClient.get(url);
};

export const quickPromptsWithId = (id) => {
  const url = `/p/document/${id}/quickPrompts`;
  return axiosClient.get(url);
};
