import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@mui/icons-material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Segmented,
  Select,
  Space,
  Typography,
  UploadFile,
  UploadProps,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getListMediaAvailable,
  uploadNewMediaFile,
  uploadNewMediaFileWithProgress,
  uploadNewMediaLink,
} from "../../../../api/mediaApi";
import { MODAL_TYPE } from "../../../../constants/modalType";
import { debounce } from "lodash";
import DraggableUploadListItem from "../../../components/DraggableUploadListItem";
import { toast } from "react-toastify";
import { ca } from "date-fns/locale";

const CreateAndEditDocumentModal = ({
  createAndEditDocumentModal,
  handleCreateUpdateDocument,
}: any) => {
  const [form] = Form.useForm();
  const [uploadType, setUploadType] = useState("Upload");
  const [listSelectMedia, setListSelectMedia] = useState<any>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [segOption, setSegOption] = useState<any>([
    { label: "Upload", value: "Upload", disable: false },
    { label: "Link", value: "Link", disable: false },
  ]);
  const [listMediaExist, setListMedisExist] = useState<any>(
    createAndEditDocumentModal?.data?.mediaData
  );

  const props: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file, FileList) => {
      if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () => {
          const result = reader.result as string;
          const count = (result.match(/\/Type[\s]*\/Page[^s]/g) || []).length;
          if (count > 100) {
            toast.warning(
              `${file.name} has over 100 pages, please upload file with less than 100 pages`
            );
            const newList = FileList.filter((item) => item.uid !== file.uid);
            setFileList([...fileList, ...newList]);
          } else {
            setFileList([...fileList, ...FileList]);
          }
        };
        console.log("FileList", file);
      } else {
        setFileList([...fileList, ...FileList]);
      }

      return false;
    },
    fileList,

    // defaultFileList: [...fileList],
    // beforeUpload: (file) => {
    //   const PDF = file.type === "application/pdf";
    //   if (!PDF) {
    //     toast.error(`${file.name} is not a pdf file`);
    //   }
    //   return PDF || Upload.LIST_IGNORE;
    // },
  };
  console.log("fileList", fileList);
  const handleProgressEvent = async (progressEvent: any, idx: any) => {
    // const { loaded, total } = progressEvent;
    // let precentage = Math.floor((loaded * 100) / total);
    // console.log("fileList", idx, fileList);
    // const fileListClone = [...fileList];
    // const file = fileListClone[idx];
    // if (precentage < 100) {
    //   fileListClone[idx] = {
    //     name: file.name,
    //     uid: file.uid,
    //     status: "uploading",
    //     percent: precentage,
    //   };
    // } else {
    //   fileListClone[idx] = {
    //     name: file.name,
    //     uid: file.uid,
    //     status: "done",
    //   };
    // }
    // setFileList([...fileListClone]);
  };

  const handleUploadMediaLink = async (links: any) => {
    try {
      const params = {
        links: links,
        mediaType: "Link",
      };
      const res: any = await uploadNewMediaLink(params);
      if (res.code === 200) {
        const listId = res?.data.map((data: any) => data.id);
        console.log(res?.data, listId);
        return listId;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  };

  const readFileAsArrayBuffer = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = () => reject(fileReader.error);
      fileReader.readAsArrayBuffer(file);
    });
  };

  const onFinish = async (values: any) => {
    if (!handleCreateUpdateDocument) return;

    if (fileList.length > 0) {
      const fileListIds = fileList.map(async (file: any, idx: any) => {
        const formData = new FormData();
        formData.append("media", file);
        const res: any = await uploadNewMediaFileWithProgress(
          formData,
          handleProgressEvent,
          idx
        );
        if (res.code === 200) {
          return res?.data[0].id;
        }
      });
      let listMediaUpdated = await Promise.all(fileListIds);
      if (createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT) {
        const oldListMedia = form.getFieldValue("fileIds");
        listMediaUpdated = [...oldListMedia, ...listMediaUpdated];
      }
      const params = {
        title: values.title,
        description: values.description,
        fileIds: listMediaUpdated,
      };
      const res = await handleCreateUpdateDocument(
        params,
        createAndEditDocumentModal.data?.id,
        createAndEditDocumentModal.data?.type
      );
      if (res === true) {
        createAndEditDocumentModal.onCancel();
        form.resetFields();
        setFileList([]);
      }
    } else if (values.links.length > 0 && fileList.length === 0) {
      let linkUpdate = await handleUploadMediaLink(values.links);
      if (createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT) {
        const oldListMedia = form.getFieldValue("fileIds");
        linkUpdate = [...oldListMedia, ...linkUpdate];
      }
      const params = {
        title: values.title,
        description: values.description,
        fileIds: linkUpdate,
      };
      const res = await handleCreateUpdateDocument(
        params,
        createAndEditDocumentModal.data?.id,
        createAndEditDocumentModal.data?.type
      );
      if (res === true) {
        createAndEditDocumentModal.onCancel();
        form.resetFields();
      }
    } else {
      const res = await handleCreateUpdateDocument(
        values,
        createAndEditDocumentModal.data?.id,
        createAndEditDocumentModal.data?.type
      );
      if (res === true) {
        createAndEditDocumentModal.onCancel();
        form.resetFields();
      }
    }
  };

  const handleEditCreateType = async () => {
    if (createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT) {
      const selectedData: [] = createAndEditDocumentModal?.data?.mediaData?.map(
        (media: any) => {
          return media.id;
        }
      );
      setListMedisExist(createAndEditDocumentModal?.data?.mediaData);
      console.log("selectedData", selectedData);
      form.setFieldValue("fileIds", selectedData);
      form.setFieldValue("title", createAndEditDocumentModal?.data?.title);
      form.setFieldValue(
        "description",
        createAndEditDocumentModal?.data?.description
      );
    }
  };

  const isYouTubeUrl = (url: string) => {
    var regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  };

  const debouncedChangeHandler = debounce(async (url, key) => {
    if (isYouTubeUrl(url)) {
      const urlObj = new URL(url);
      const videoId = urlObj.searchParams.get("v");
      const oembedBaseUrl = "https://www.youtube.com/oembed";
      const oembedUrl = new URL(oembedBaseUrl);
      oembedUrl.searchParams.append("url", `youtube.com/watch?v=${videoId}`);
      oembedUrl.searchParams.append("format", "json");

      const res: any = await fetch(oembedUrl.href).then((res) => res.json());
      form.setFieldValue(key, res.title);
    }
  }, 1000);

  const handleRemoveLink = (key: any) => {
    form.setFieldValue(key, "");
  };

  const handleChangeInput = (e: any, item: any) => {
    debouncedChangeHandler(e.target.value, ["links", item.index, "title"]);
  };

  useEffect(() => {
    if (createAndEditDocumentModal.visible) {
      handleEditCreateType();
    }

    return () => {
      form.resetFields();
      setListSelectMedia([]);
    };
  }, [createAndEditDocumentModal.visible]);

  return (
    <Modal
      open={createAndEditDocumentModal.visible}
      onCancel={() => {
        form.resetFields();
        setFileList([]);
        createAndEditDocumentModal.onCancel();
      }}
      title={`${
        createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT
          ? "Update Document"
          : "Create Document"
      }`}
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Title:"
          name="title"
          rules={[{ required: true, message: "Please input document title!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description:"
          name="description"
          rules={[
            { required: true, message: "Please input document description!" },
          ]}
        >
          <Input />
        </Form.Item>
        {createAndEditDocumentModal.data?.type === MODAL_TYPE.EDIT &&
          listMediaExist?.length > 0 && (
            <Flex vertical gap={5}>
              <Typography.Text strong>Medias in document:</Typography.Text>
              <div style={{ overflow: "scroll", maxHeight: 300 }}>
                {listMediaExist.map((media: any) => (
                  <div key={media.id}>
                    <Flex align="center" gap={5}>
                      <Flex>
                        <InsertDriveFileOutlinedIcon />
                        <span className="ellipsis" style={{ color: "#000" }}>
                          {media.name}
                        </span>
                      </Flex>
                    </Flex>
                  </div>
                ))}
              </div>
            </Flex>
          )}
        <>
          <div style={{ minHeight: 250 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Segmented
                options={segOption}
                onChange={(val: any) => setUploadType(val)}
                value={uploadType}
                size="large"
              />
            </div>
            <div>
              {uploadType === "Upload" ? (
                <div style={{ padding: "10px" }}>
                  <Dragger
                    {...props}
                    itemRender={(originNode, file) => (
                      <DraggableUploadListItem
                        originNode={originNode}
                        file={file}
                      />
                    )}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Supports uploading multiple files at once, the types of
                      files that can be uploaded include jpg, png, audio, mp3.
                    </p>
                  </Dragger>
                </div>
              ) : (
                <div style={{ overflow: "auto", maxHeight: "40vh" }}>
                  <div style={{ maxWidth: 600, padding: "10px" }}>
                    <Form.List name="links">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Flex
                              key={key}
                              align="center"
                              justify="center"
                              gap={10}
                              style={{
                                borderBottom: "1px solid grey",
                                marginBottom: 10,
                              }}
                            >
                              <Flex vertical style={{ width: "100%" }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing url",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter url"
                                    onChange={(e: any) => {
                                      const params = {
                                        key,
                                        name,
                                        index,
                                        ...restField,
                                      };
                                      handleChangeInput(e, params);
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "title"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing title",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Title" />
                                </Form.Item>
                              </Flex>
                              <CloseOutlined
                                style={{ marginTop: "-20px" }}
                                onClick={() => {
                                  handleRemoveLink([name, "title"]);
                                  remove(name);
                                }}
                              />
                            </Flex>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add more link
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    <Form.Item
                      style={{ display: "flex", justifyContent: "center" }}
                    ></Form.Item>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      </Form>
    </Modal>
  );
};

export default CreateAndEditDocumentModal;
