import styled from "@emotion/styled";
import SendIcon from "@mui/icons-material/Send";
import { Box, Card, Fab, Grid, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState } from "react";

const TextField = styled(MuiTextField)(spacing);

const ChatWrap = styled(Grid)`
  display: flex;
  width: 100%;
  flex: none !important;
  // height: 56px;
  // height: 100%;
  padding: ${(props) => props.theme.spacing(1)};
  padding-bottom: ${(props) => props.theme.spacing(0)};
`;

const ChatInput = ({
  handleSendMessage,
  isMobile,
  message,
  setMessage,
}: any) => {
  return (
    <ChatWrap style={{ alignItems: "center", flex: 1 }}>
      <Grid item style={{ flex: 1, position: "relative" }}>
        <TextField
          variant="outlined"
          label="Type your message"
          fullWidth
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13) {
              handleSendMessage(message);
              setMessage("");
            }
          }}
        />
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          style={{
            position: "absolute",
            top: "50%",
            right: 10,
            marginTop: -20,
          }}
          component={Card as any}
          onClick={() => {
            handleSendMessage(message);
            setMessage("");
          }}
        >
          <SendIcon />
        </Fab>
      </Grid>
    </ChatWrap>
  );
};

export default React.memo(ChatInput);
