/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid, Input as InputText } from "@mui/material";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteChat,
  getByChatId,
  getListChat,
  getMessageByChatId,
  updateChatTitle,
} from "../../../api/chatApi";
import useViewport from "../../../hooks/useViewport";
import { addNewChatList, updateChatList } from "../../../redux/slices/chatList";
import "./ChatAi.scss";
import ChatInput from "./components/ChatInput";
import ChatMessageList from "./components/ChatMessageList";
import { splitArrayOnValue, Utf8ArrayToStr } from "./utility";
import { Button, Flex, Popconfirm } from "antd";
import { quickPrompts } from "../../../api/suggestionApi";
import Suggestions from "./components/Suggestions";
import { useModalProps } from "../../../hooks/useModalProps";
import PinList from "./components/PinList";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

const ChatMain = styled(Grid)`
  flex: 1;
  display: flex;
`;

const ChatWrapped = styled("div")`
  max-width: 900px;
  margin: auto;
  width: 100%;
  position: relative;
`;

const ChatPage = () => {
  const viewPort = useViewport();
  const isMobile = viewPort.width <= 958;
  const accessToken: any = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const paramValue: any = queryParams.get("id");

  const pinListDraw = useModalProps();

  const ws = useRef<any>(null);
  const chatBoxRef = useRef<any>(null);
  const chatMainRef = useRef<any>(null);

  const [message, setMessage] = useState<any>("");
  const [chatData, setChatData] = useState<any>({});
  const [botMessage, setBotMessage] = useState<any>("");
  const [mesageList, setMessageList] = useState<any>([]);
  const [isDisableSendMessage, setIsDisableSendMessage] = useState(false);
  const [isStartChat, setIsStartChat] = useState(false);
  const [statusCode, setStatusCode] = useState<any>(null);
  const [ishasMore, setIsHasMore] = useState(false);
  const [cursorId, setCursorId] = useState("");
  const [idNew, setIdNew] = useState<any>(null);
  const [newTitle, setNewTitle] = useState<any>(null);
  const [documentId, setDocumentId] = useState<any>(null);

  const handleSendMessage = (message: string) => {
    if (message === "" || isDisableSendMessage) return;
    if (!isStartChat) {
      setIsStartChat(true);
      setNewTitle(message);
      setIsDisableSendMessage(true);
      setMessageList([{ message: message, position: "right" }, ...mesageList]);
      if (paramValue === null) {
        ws.current = new WebSocket(
          `${process.env.REACT_APP_SOCKET_URL}/botchatService?&socauth=${accessToken}&firstMessage=${message}`
        );
      } else {
        ws.current = new WebSocket(
          `${process.env.REACT_APP_SOCKET_URL}/botchatService?${
            `chatId=` + paramValue
          }&socauth=${accessToken}&firstMessage=${message}`
        );
      }
      return true;
    } else {
      ws.current?.send(message);
      setIsDisableSendMessage(true);
      setMessageList([{ message: message, position: "right" }, ...mesageList]);
      return true;
    }
  };

  const handleGetChatData = async (id: any) => {
    try {
      const { data } = await getByChatId(id);
      if (data) {
        setChatData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMessageBydChatId = async (id: any, cursor: any = "") => {
    try {
      const data: any = await getMessageByChatId(id, cursor);
      if (data.code === 200) {
        const listChat = data?.data;
        const listChatId = listChat.map((chatItem: any) => {
          const message = chatItem.message;
          return {
            id: chatItem.id,
            message: message,
            position:
              chatItem.createdBy === "000000000000000000000000"
                ? "left"
                : "right",
            documentId: chatItem?.relevantDocumentIDs,
          };
        });
        setMessageList(listChatId);
        setIsHasMore(data?.meta?.has_more);
        setCursorId(data?.meta?.cursor);
      } else {
        setMessageList([]);
        setIsHasMore(false);
        setCursorId("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddDataOnScroll = async (id: any, cursor: any = "") => {
    try {
      if (!id || !cursor) return;
      const data: any = await getMessageByChatId(id, cursor);
      if (data.code === 200) {
        const listChat = data?.data;
        const listChatId = listChat
          ? listChat?.map((chatItem: any) => {
              return {
                message: chatItem?.message,
                position:
                  chatItem?.createdBy === "000000000000000000000000"
                    ? "left"
                    : "right",
                documentId: chatItem?.relevantDocumentIDs,
              };
            })
          : [];
        setMessageList((list: any) => [...list, ...listChatId]);
        setIsHasMore(data?.meta?.has_more);
        setCursorId(data?.meta?.cursor);
      } else {
        setMessageList([]);
        setIsHasMore(false);
        setCursorId("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = (e: any) => {
    if (
      -e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
      ishasMore
    ) {
      AddDataOnScroll(paramValue, cursorId);
    }
  };

  const exportChatToTxt = () => {
    let content = "";

    // Duyệt qua mảng đoạn chat và thêm từng tin nhắn vào nội dung văn bản với căn lề tương ứng
    mesageList.forEach((item: any) => {
      if (item.position === "left") {
        // Tin nhắn từ hệ thống, căn lề phải
        content += `${"BOT: " + item.message}\n`;
      } else {
        // Tin nhắn từ người dùng, căn lề trái
        content += `${"USER: " + item.message}\n`;
      }
    });

    // Tạo Blob từ nội dung văn bản
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });

    // Sử dụng thư viện FileSaver để tạo và tải xuống tệp tin
    saveAs(blob, `${chatData.title}.txt`);
  };

  const handleUpdateTitle = async (id: any, title: any) => {
    try {
      if (title === "") return;
      await updateChatTitle(id, { title: title });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetQuickPromts = async () => {
    const res: any = await quickPrompts();
  };

  useEffect(() => {
    if (statusCode === "200") {
      setMessageList([
        {
          message: botMessage,
          position: "left",
          documentId: documentId,
        },
        ...mesageList,
      ]);
      setIsDisableSendMessage(false);
      setBotMessage("");
      setStatusCode(null);
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;

      if (documentId?.length > 0) {
        setDocumentId(null);
      }

      if (paramValue === null) {
        localStorage.setItem("current-chat-id", idNew);
        navigate(`/?id=${idNew}`);
        setIdNew(null);
      }
    }
  }, [statusCode, documentId]);

  useEffect(() => {
    if (idNew !== null) {
      const params: any = {
        id: idNew,
        title: newTitle,
      };
      handleUpdateTitle(idNew, newTitle);
      const action = addNewChatList(params);
      dispatch(action);
    }
  }, [idNew]);

  useEffect(() => {
    if (!ws.current) return;
    ws.current.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    ws.current.onmessage = (event: any) => {
      if (mesageList.length === 0) return;
      const myString = event.data;
      const encoder = new TextEncoder();
      const encodedArray: any = encoder.encode(myString);
      const listMessage = splitArrayOnValue(Array.from(encodedArray));
      listMessage.forEach((message: any) => {
        const statusCode = Utf8ArrayToStr([message[0], message[1], message[2]]);
        if (statusCode === "200") {
          setStatusCode("200");
        }
        if (statusCode === "201") {
          message.splice(0, 4);
          message.splice(-2);
          const stringDecode = Utf8ArrayToStr(message);
          setIdNew(stringDecode);
        }
        if (statusCode === "100") {
          message.splice(0, 4);
          message.splice(-2);
          if (message[message.length - 1] === 3) {
            message.splice(-1);
          }
          const stringDecode = Utf8ArrayToStr(message);
          setBotMessage((prev: any) => prev + stringDecode);
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight + 100;
        }
        if (statusCode === "226") {
          message.splice(0, 4);
          message.splice(-2);
          const stringDecode = Utf8ArrayToStr(message);
          const idDocument = stringDecode.split(",");
          idDocument.length && idDocument[0].length > 0
            ? setDocumentId(idDocument)
            : setDocumentId(null);
        }
      });
    };

    ws.current.onerror = (error: any) => {
      setIsStartChat(false);
      setIsDisableSendMessage(false);
      setBotMessage((prev: any) => "");
      setStatusCode(null);
    };

    ws.current.onclose = (event: any) => {
      setIsStartChat(false);
      setIsDisableSendMessage(false);
      setBotMessage((prev: any) => "");
      setStatusCode(null);
    };
  }, [mesageList, ws.current]);

  useEffect(() => {
    setMessage("");
    const idChat = localStorage.getItem("current-chat-id");
    if (idChat && paramValue === null) {
      navigate(`/?id=${idChat}`);
    } else if (paramValue !== null) {
      handleGetChatData(paramValue);
      handleGetMessageBydChatId(paramValue);
      localStorage.setItem("current-chat-id", paramValue);
    } else {
      setChatData({
        title: "New Chat",
      });
    }
  }, [paramValue]);

  useEffect(() => {
    if (location.search === "") {
      setMessageList([]);
      ws.current && ws.current.close();
      setIsStartChat(false);
      setCursorId("");
      setIsHasMore(false);
    }
  }, [location.search]);

  useEffect(() => {
    handleGetQuickPromts();

    setIsStartChat(false);
    const idChat = localStorage.getItem("current-chat-id");
    if (idChat) {
      navigate(`/?id=${idChat}`);
    }
    return () => {
      setMessageList([]);
      ws.current && ws.current.close();
      setIsStartChat(false);
      setCursorId("");
      setIsHasMore(false);
    };
  }, []);

  return (
    <div className="chat-page-container">
      <ChatHeader
        chatData={chatData}
        paramValue={paramValue}
        mesageList={mesageList}
        exportChatToTxt={exportChatToTxt}
      />

      <ChatWrapped>
        <ChatMain item xs={24} lg={24} ref={chatMainRef}>
          <ChatMessageList
            chatBoxRef={chatBoxRef}
            mesageList={mesageList}
            handleScroll={handleScroll}
            isStartChat={isStartChat}
            botMessage={botMessage}
            isDisableSendMessage={isDisableSendMessage}
          />
        </ChatMain>
        <Suggestions
          mesageListLength={mesageList.length}
          lastMessage={mesageList[0]}
          setMessage={setMessage}
          isDisableSendMessage={isDisableSendMessage}
        />
        <ChatInput
          handleSendMessage={handleSendMessage}
          isMobile={isMobile}
          message={message}
          setMessage={setMessage}
        />
      </ChatWrapped>
      <Flex
        onClick={pinListDraw.onOpen}
        style={{ position: "absolute", right: 0, top: "50%" }}
      >
        <KeyboardBackspaceOutlinedIcon />
      </Flex>
      <PinList pinListDraw={pinListDraw} />
    </div>
  );
};

const ChatHeader = React.memo(
  ({ chatData, paramValue, exportChatToTxt }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disableInput, setDisableInput] = useState(true);
    const [title, setTitle] = useState("");
    const inputRef = useRef<any>(null);

    const handleGetListChat = async () => {
      const { data } = await getListChat();
      // setChatList(data);
      const action = updateChatList(data);
      dispatch(action);
    };

    const handleChangeTitle = (title: any) => {
      setTitle(title);
    };

    const handleUpdateTitle = async () => {
      if (title === "") return;
      const res: any = await updateChatTitle(chatData.id, { title: title });
      if (res.code === 200) {
        setDisableInput(true);
        handleGetListChat();
      }
    };

    const handleDeleteChat = async () => {
      if (!paramValue) return;
      const res: any = await deleteChat(paramValue);
      if (res.code === 200) {
        navigate(`/`);
        localStorage.removeItem("current-chat-id");
        const { data } = await getListChat();
        const action = updateChatList(data);
        dispatch(action);
        toast.success("Delete success!");
      }
    };

    useEffect(() => {
      if (inputRef && inputRef.current && disableInput) {
        inputRef.current.focus();
      }
    }, [disableInput]);

    useEffect(() => {
      setTitle(chatData.title);
      return () => {
        setDisableInput(true);
      };
    }, [chatData]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: 35,
          backgroundColor: "#fff",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <div style={{ flex: 1 }}>
          <InputText
            autoFocus
            inputRef={inputRef}
            disabled={disableInput}
            value={title}
            sx={{
              fontSize: 17,
              color: "#000",
              width: "90%",
              padding: 0,
            }}
            disableUnderline
            onChange={(e: any) => handleChangeTitle(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {disableInput && (
            <EditOutlinedIcon
              fontSize="medium"
              onClick={() => setDisableInput(false)}
              className="cursor-pointer"
            />
          )}
          {!disableInput && (
            <>
              <CheckOutlinedIcon
                fontSize="medium"
                onClick={handleUpdateTitle}
                className="cursor-pointer"
              />
              <ClearOutlinedIcon
                fontSize="medium"
                onClick={() => setDisableInput(true)}
                className="cursor-pointer"
              />
            </>
          )}

          <DownloadOutlinedIcon
            fontSize="medium"
            className="cursor-pointer"
            onClick={exportChatToTxt}
          />
          {paramValue && (
            <Popconfirm
              title="Delete Chat"
              placement="topLeft"
              description="Are you sure you want to delete this chat?"
              onConfirm={handleDeleteChat}
            >
              <DeleteOutlineOutlinedIcon
                fontSize="medium"
                className="cursor-pointer"
              />
            </Popconfirm>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(ChatPage);
