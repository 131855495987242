import styled from "@emotion/styled";
import React, { useEffect } from "react";
import ChatMessageComponent from "./ChatMessageComponent";

const ChatMessagesWrapped = styled.div`
  overflow: scroll;
  padding-top: 0;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
`;

const ChatMessageList = ({
  chatBoxRef,
  mesageList,
  handleScroll,
  isStartChat,
  botMessage,
  isDisableSendMessage,
}: any) => {
  useEffect(() => {
    chatBoxRef.current.style.height = `${window.innerHeight - 200}px`;
  }, [chatBoxRef]);

  return (
    <ChatMessagesWrapped ref={chatBoxRef} onScroll={(e) => handleScroll(e)}>
      {isStartChat && botMessage !== "" && isDisableSendMessage && (
        <ChatMessageComponent
          message={botMessage}
          position={"left"}
          key="bot-message1"
        />
      )}
      {isStartChat && botMessage === "" && isDisableSendMessage && (
        <ChatMessageComponent
          message={"Chatbot is writing.."}
          position={"left"}
          key="bot-message2"
        />
      )}
      {mesageList.map((items: any, idx: any) => (
        <ChatMessageComponent
          key={items.id ? items.id + idx : idx}
          message={items.message}
          position={items.position}
          documentId={items?.documentId}
          chatBoxRef={chatBoxRef}
          chatItem={items}
        />
      ))}
    </ChatMessagesWrapped>
  );
};

export default React.memo(ChatMessageList);
