import { Flex, Modal, Typography } from "antd";
import { useEffect } from "react";
import PdfIcon from "../../../../assets/image/pdf-icon.svg";
import YtbIcon from "../../../../assets/image/ytb-icon.png";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { MEDIA_SUB_TYPE } from "../../../../constants/mediaType";
import React from "react";

const MediaInDocumentModal = ({ mediaInDocumnetModal, handlePreview }: any) => {
  const renderTextWithBold = (text: string): JSX.Element => {
    // Sử dụng regex để tìm và thay thế phần văn bản trong dấu "**"
    const parts = text.split(/(\*\*[^*]+\*\*)/g).map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        // Nếu phần này nằm trong dấu "**", bôi đen nó
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else {
        // Nếu không, giữ nguyên phần văn bản này
        return <React.Fragment key={index}>{part}</React.Fragment>;
      }
    });

    return <span>{parts}</span>;
  };

  return (
    <Modal
      open={mediaInDocumnetModal.visible}
      onCancel={mediaInDocumnetModal.onCancel}
      title="Media List"
      footer={false}
      className="media-list-modal"
    >
      <div
        style={{
          paddingLeft: 20,
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        {mediaInDocumnetModal.data ? (
          mediaInDocumnetModal.data.map((media: any) => (
            <>
              <Flex align="center">
                <Flex align="center" gap={5} justify="space-between">
                  <Flex align="center" gap={3}>
                    <img
                      src={
                        media.subType === MEDIA_SUB_TYPE.PDF ? PdfIcon : YtbIcon
                      }
                      height={25}
                      width={25}
                      alt=""
                    />
                    {media?.name} ({media?.owner?.fullName})
                  </Flex>
                  <VisibilityOutlinedIcon
                    fontSize="small"
                    className="cursor-pointer"
                    onClick={() => handlePreview(media)}
                  />
                </Flex>
              </Flex>
              <Flex>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {renderTextWithBold(media.summaryMedia)}
                </Typography>
              </Flex>
            </>
          ))
        ) : (
          <Typography>Loading Summary</Typography>
        )}
      </div>
    </Modal>
  );
};

export default MediaInDocumentModal;
